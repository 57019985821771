[class*='rs-animation-'] {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .rs-animation-fade {
    -webkit-animation-name: rs-fade;
    animation-name: rs-fade;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-timing-function: linear !important;
    animation-timing-function: linear !important;
  }
  .rs-animation-slide-top {
    -webkit-animation-name: rs-fade-top;
    animation-name: rs-fade-top;
  }
  .rs-animation-slide-bottom {
    -webkit-animation-name: rs-fade-bottom;
    animation-name: rs-fade-bottom;
  }
  .rs-animation-slide-left {
    -webkit-animation-name: rs-fade-left;
    animation-name: rs-fade-left;
  }
  .rs-animation-slide-right {
    -webkit-animation-name: rs-fade-right;
    animation-name: rs-fade-right;
  }
  .rs-animation-scale-up {
    -webkit-animation-name: rs-animation-scale-up;
    animation-name: rs-animation-scale-up;
  }
  .rs-animation-scale-down {
    -webkit-animation-name: rs-animation-scale-down;
    animation-name: rs-animation-scale-down;
  }
  .rs-animation-shake {
    -webkit-animation-name: rs-shake;
    animation-name: rs-shake;
  }
  .rs-animation-rotate {
    -webkit-animation-name: rs-rotate;
    animation-name: rs-rotate;
  }
  .rs-animation-scale {
    -webkit-animation-name: rs-scale;
    animation-name: rs-scale;
  }
  .rs-animation-hover:not(:hover),
  .rs-animation-hover:not(:hover) [class*='rs-animation-'] {
    -webkit-animation-name: none;
    animation-name: none;
  }
  .rs-animation-reverse {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  .rs-image-effect-shine {
    position: relative;
    overflow: hidden;
  }
  .rs-image-effect-shine::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }
  .rs-image-effect-shine:hover::before {
    -webkit-animation: img-shine 1s;
    animation: img-shine 1s;
  }
  @-webkit-keyframes img-shine {
    100% {
      left: 125%;
    }
  }
  @keyframes img-shine {
    100% {
      left: 125%;
    }
  }
  /*-------------------------
      Fade
  ---------------------------*/
  @-webkit-keyframes rs-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes rs-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /*---------------------------
      Fade Top
  ------------------------------*/
  @-webkit-keyframes rs-fade-top {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-60%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  @keyframes rs-fade-top {
    0% {
      opacity: 0;
      transform: translateY(-60%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  /*---------------------------
      Fade Bottom
  ------------------------------*/
  @-webkit-keyframes rs-fade-bottom {
    0% {
      opacity: 0;
      -webkit-transform: translateY(60%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  @keyframes rs-fade-bottom {
    0% {
      opacity: 0;
      transform: translateY(60%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  /*---------------------------
      Fade Left
  ------------------------------*/
  @-webkit-keyframes rs-fade-left {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-60%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }
  @keyframes rs-fade-left {
    0% {
      opacity: 0;
      transform: translateX(-60%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  /*---------------------------
      Fade Right
  ------------------------------*/
  @-webkit-keyframes rs-fade-right {
    0% {
      opacity: 0;
      -webkit-transform: translateX(60%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }
  @keyframes rs-fade-right {
    0% {
      opacity: 0;
      transform: translateX(60%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  /*------------------------------
     Scale Up
  --------------------------------*/
  @-webkit-keyframes rs-animation-scale-up {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.2);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }
  @keyframes rs-animation-scale-up {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  /*---------------------------
      Scale Down
  ------------------------------*/
  @-webkit-keyframes rs-animation-scale-down {
    0% {
      opacity: 0;
      -webkit-transform: scale(1.6);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }
  @keyframes rs-animation-scale-down {
    0% {
      opacity: 0;
      transform: scale(1.6);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  /*---------------------------
      Rotate
  ------------------------------*/
  @-webkit-keyframes rs-rotate {
    0% {
      -webkit-transform: rotate(280deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes rs-rotate {
    0% {
      transform: rotate(280deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /*---------------------------
      Shake
  ------------------------------*/
  @-webkit-keyframes rs-shake {
    0%,
    100% {
      -webkit-transform: translateX(0);
    }
    10% {
      -webkit-transform: translateX(-9px);
    }
    20% {
      -webkit-transform: translateX(8px);
    }
    30% {
      -webkit-transform: translateX(-7px);
    }
    40% {
      -webkit-transform: translateX(6px);
    }
    50% {
      -webkit-transform: translateX(-5px);
    }
    60% {
      -webkit-transform: translateX(4px);
    }
    70% {
      -webkit-transform: translateX(-3px);
    }
    80% {
      -webkit-transform: translateX(2px);
    }
    90% {
      -webkit-transform: translateX(-1px);
    }
  }
  @keyframes rs-shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(-9px);
    }
    20% {
      transform: translateX(8px);
    }
    30% {
      transform: translateX(-7px);
    }
    40% {
      transform: translateX(6px);
    }
    50% {
      transform: translateX(-5px);
    }
    60% {
      transform: translateX(4px);
    }
    70% {
      transform: translateX(-3px);
    }
    80% {
      transform: translateX(2px);
    }
    90% {
      transform: translateX(-1px);
    }
  }
  /*-------------------
      Scale
  ---------------------*/
  @-webkit-keyframes rs-scale {
    0% {
      -webkit-transform: scale(1.2);
    }
    100% {
      -webkit-transform: scale(1);
    }
  }
  @keyframes rs-scale {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }