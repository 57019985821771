.rs-services-style1 .services-icon {
    height: 130px;
    width: 130px;
    background-color: #212121;
    line-height: 88px;
    text-align: center;
    position: absolute;
    top: -50px;
    z-index: -1;
    font-size: 40px;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #ff3115;
    transition: all 0.3s ease 0s;
}
.rs-services-style1 .services-item {
    background-color: #212121;
    padding: 25px 17px 17px;
    box-shadow: 0 4px 2px -2px #000000;
    z-index: 111;
    position: relative;
    top: -30px;
    transition: all 0.3s ease 0s;
}

.rs-services-style1 .services-desc {
    text-align: center;
    margin-top: 6px;
}
.rs-services-style1 .services-desc .services-title {
    position: relative;
    color: #ffffff;
    font-size: 26px;
    margin-bottom: 7px;
    z-index: 10;
    font-weight: bold;
}
.rs-services-style1 .services-desc p {
    color: #cccccc;
    margin-bottom: 0;
}
.rs-services-style1{ position:relative; z-index: 999;}

.slick-list {
    position: relative;
    display: block;
    overflow:visible;
    margin: 0;
    padding: 0;
}

.rs-services-1{ background-color:#6f92da ; padding: 100px 0px;}

.rs-services-1 .slick-list {
    position: relative;
    display: block;
    overflow:hidden;
    margin: 0;
    padding: 0;
}