body{ overflow-x: hidden; text-align: justify;}

.ptb-30{ padding: 30px 0px;}
.ptb-50{ padding: 50px 0px;}
.features h1{ margin: 25px 0px;}
.features h2{ margin: 25px 0px;}

.features ul{  margin: 10px 0px 35px 0px;}
.features li{ margin: 10px 0px; text-align: justify;}

.bg4 {
    background-image: url(../images/cta-bg.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center top;
}

.sec-spacer {
    padding: 100px 0;
}

.mb-50 {
    margin-bottom: 50px !important;
}
.sec-title {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 20px;
}
.sec-title h2 {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.white-color {
    color: #fff !important;
}
.sec-title p {
    margin-bottom: 0;
    font-size: 16px;
}
.sec-title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100px;
    background: #ff3115;
}
.sec-title.text-center:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.rs-calltoaction .cta-button {
    display: inline-block;
    text-decoration: none;
    background-color: #ff3115;
    color: #ffffff;
    font-weight: 500;
    min-width: 170px;
    line-height: 45px;
    height: 45px;
    text-align: center;
    text-transform: uppercase;
}

.rs-events .event-item .event-img {
    margin-bottom: 20px;
}

.event-item .events-details {
    margin-top: -20px;
    padding: 15px;
}
.sec-color {
    background-color: #c1deff;
}

.rs-events .event-item .event-title {
    font-size: 20px;
    margin-bottom: 8px;
    margin-top: 4px;
}
.rs-events .event-item .event-meta {
    font-size: 14px;
}
.rs-events .event-item .event-btn {
    margin-top: 18px;
}

.event-item .event-img .image-link, .cource-item .event-img .image-link, .event-item .cource-img .image-link, .cource-item .cource-img .image-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    color: #ff3115;
    z-index: 11;
}
.event-item .event-img:after, .cource-item .event-img:after, .event-item .cource-img:after, .cource-item .cource-img:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: 0.4s;
    -ms-transition: 0.4s;
    transition: 0.4s;
}
.event-item .event-img .image-link i, .cource-item .event-img .image-link i, .event-item .cource-img .image-link i, .cource-item .cource-img .image-link i {
    font-size: 22px;
    font-weight: 400;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    transition: all 0.3s ease 0s;
}