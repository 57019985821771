.rs-toolbar { padding: 4px 0 5px;  background-color: #111111;}
.rs-toolbar .rs-toolbar-left .welcome-message {
    font-size: 14px;
}
.rs-toolbar .rs-toolbar-left .welcome-message i {
    color: #ff3115;
    margin-right: 8px;
}
.rs-toolbar .rs-toolbar-left .welcome-message span {
    color: #ffffff;
}
.rs-toolbar .rs-toolbar-right {
    text-align: right;
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon {
    display: inline-block;
    margin-right: 30px;
}

.rs-toolbar .rs-toolbar-right .apply-btn {
    font-weight: 500;
    font-size: 14px;
}
a {
    color: #ff3115;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    outline: none !important;
    
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul{ margin: 0px; padding: 0px;}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li {
    display: inline-block; margin: 0px 5px;
}
.rs-toolbar .rs-toolbar-right .toolbar-share-icon ul li a {
    font-size: 14px;
    color: #888888;
}
.apply-btn{ background-color: #ff3115; padding: 8px; color: #fff; font-weight: bold; border-radius: 4px;}

 .rs-header-top {
    padding: 25px 0px;
}

.rs-header-top .col-md-4:first-child .header-contact {
    float: left;
}
.rs-header-top .header-contact .widget-text {
    position: relative;
    padding-left: 55px;
}
.rs-header-top .header-contact .widget-text i {
    position: absolute;
    border-radius: 2px;
    text-align: center;
    left: 0;
    line-height: 40px;
    color: #ff3115;
    font-size: 35px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    font-weight: 700;
}
 .rs-header-top .header-contact .widget-text .info-text a {
    color: #505050;
    font-weight: 400;
    font-size: 16px;
}
.rs-header-top .header-contact .widget-text .info-text span {
    display: block;
    font-weight: 700;
    color: #101010;
    line-height: 18px;
}
.logo-area {
    padding-top: 3px;
}
.rs-header-top .header-contact {
    float: right;
}
 .rs-header-top .header-contact .widget-text {
    position: relative;
    padding-left: 55px;
}
 .rs-header-top .header-contact .widget-text i {
    position: absolute;
    border-radius: 2px;
    text-align: center;
    left: 0;
    line-height: 40px;
    color: #ff3115;
    font-size: 35px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    font-weight: 700;
}
 .rs-header-top .header-contact .widget-text .info-text a {
    color: #505050;
    font-weight: 400;
    font-size: 16px;
    font-weight: bold;
}

 .rs-header-top .header-contact .widget-text .info-text span {
    display: block;
    font-weight: 700;
    color: #101010;
    line-height: 18px;
}