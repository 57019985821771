.rs-slider { position: relative;}
.rs-slider .slide-content {
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    z-index: 8;
}
.display-table {
    display: table;
    height: 100%;
    width: 100%;
}
.display-table-cell {
    display: table-cell;
    vertical-align: middle;
}
.rs-slider .slide-content .slider-title {
    font-size: 60px;
    color: #fff;
    margin-bottom: 15px;
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    text-transform: uppercase;
}
.rs-slider .slide-content .slider-desc {
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 20px;
}
.rs-slider .slide-content .sl-readmore-btn {
    border: 2px solid #101010;
    background: #101010;
    display: inline-block;
    padding: 12px 25px;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease 0s;
}
.rs-slider .slide-content .sl-get-started-btn {
    background-color: #ff3115;
    border: 2px solid #ff3115;
    display: inline-block;
    padding: 12px 25px;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease 0s;
}

.slider-overlay-2 .slide-content:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 109, 0, 0.3);
    z-index: -1;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
    background: linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 109, 0, 0.3) 100%);
    background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.3) 100%);
    background: -webkit-gradient(linear, right top, right bottom, color-stop(0%, rgba(0, 0, 0, 0.55)), color-stop(50%, rgba(0, 0, 0, 0.5)), color-stop(100%, rgba(0, 0, 0, 0.3)));
    opacity: 0.95;
}
.mr-30 {
    margin-right: 30px !important;
}